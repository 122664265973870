import { Image as ImageType } from '@zupr/types/fo'
import classnames from 'classnames'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'

import Chevron from '../../../../svg/chevron.svg'
import Img from '../../components/image'

// thumbnail index is moved by one to put the first in the middle
interface ThumbnailsProps {
    currentSlide: number
    goTo: (to: number) => void
    alt: string
    images: ImageType[]
    cover?: boolean
}

const SlidingThumbnails = ({
    currentSlide,
    goTo,
    images,
    cover,
    alt,
}: ThumbnailsProps) => {
    const [sliderRef] = useKeenSlider<HTMLDivElement>({
        loop: true,
        slidesPerView: 3,
        spacing: 8,
        initial: currentSlide,
        controls: false,
    })

    return (
        <div className="carousel-thumbnails-holder keen-slider" ref={sliderRef}>
            {images.map((image, i) => (
                <div
                    key={`slider-${i}`}
                    className={classnames('keen-slider__slide', { cover })}
                >
                    <button
                        key={i}
                        onClick={() => {
                            if (i - 1 < 0) return goTo(i - 1 + images.length)
                            goTo(i - 1)
                        }}
                        className={classnames({
                            active:
                                i === currentSlide + 1 ||
                                i === currentSlide - images.length + 1,
                        })}
                    >
                        <Img image={image} alt={alt} small />
                    </button>
                </div>
            ))}
        </div>
    )
}

const NonSlidingThumbnails = ({
    currentSlide,
    goTo,
    images,
    cover,
    alt,
}: ThumbnailsProps) => (
    <div className="carousel-thumbnails-holder">
        {images.map((image, i) => (
            <div key={`NonSlidingThumbnails-${i}`}>
                <button
                    key={i}
                    onClick={() => goTo(i)}
                    className={classnames('keen-slider__slide', {
                        cover,
                        active: i === currentSlide,
                    })}
                >
                    <Img image={image} alt={alt} small />
                </button>
            </div>
        ))}
    </div>
)

interface Props extends ThumbnailsProps {
    next: () => void
    prev: () => void
}

const Thumbnails = ({ next, prev, images, ...props }: Props) => {
    const shifted = [...images]
    shifted.unshift(shifted.pop())

    return (
        <div className="carousel-thumbnails" data-length={images.length}>
            <div className="carousel-navigation">
                <button type="button" onClick={prev} className="carousel-left">
                    <Chevron />
                </button>
                <button type="button" onClick={next} className="carousel-right">
                    <Chevron />
                </button>
            </div>
            {images.length > 3 && (
                <SlidingThumbnails {...props} images={shifted} />
            )}
            {images.length <= 3 && (
                <NonSlidingThumbnails {...props} images={images} />
            )}
        </div>
    )
}

export default Thumbnails
